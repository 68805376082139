main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    text-align: center;
    color: white;
}

main h5 {
    font-size: 28px;
    letter-spacing: 20px;
    
    margin-bottom: 35px;
}


main h1 {
    font-size: 85px;
    font-weight: 300;
    margin-bottom: 45px;
}

.brand {
    font-size: 95px;
    font-weight: 500;
}


@media (max-width: 576px) {
    main h5 {
        font-size: 16px;
    }
    main h1 {
        font-size: 35px;
    }
    .brand {
        font-size: 55px;
    }
    nav {
        background-color: black;
    }
}



@media (max-width: 768px) {
    
}

@media (min-width: 992px) {
    
}

@media (min-width: 1200px) {
    
}

@media (min-width: 1400px) {
    
}