.dashboard {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.dashboard-container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}


