.logo {
    height: 65px;
}

nav {
    z-index: 9999;

}

.login-btn {
    background-color: #a3712a ;
    border: none;
    padding: 8px 45px !important;
    border-radius: 25px;
    color: white;
}


/* 
@media (max-width: 576px) {
  
    nav {
        background-color: rgb(0, 0, 0);
    }
} */
