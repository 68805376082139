footer {
    padding: 65px;
    background-color: #212529;
    color: #999999;
    font-weight: 200;
}

.footer-logo {
    height: 105px;
    width: 130px;
    margin-bottom: 5px;
}

footer p {
    line-height: 19px;
    font-size: 15px;
}

footer ul li {
    list-style-type: square;
}

.quick-link li a {
    text-decoration: none;
    color: #999999;
}

.quick-link li a:hover {
    color: #a3712a;
}

footer table td, th {
    color: #999999;
    font-size: 14px;
    line-height: 12px;
}

.footer-social-link i {
    color: white;
    text-align: center;
    font-size: 18px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: #a3712a;
    margin: 0 2px;
}

@media (max-width: 576px) {
    footer {
        padding: 35px;
    }
    .footer-logo {
        height: 85px;
        width: 100px;
        margin-bottom: 10px;
    }
    .quick-heading {
        margin-top: 20px;
    }
    .quick-link {
        margin-bottom: 10px;
    }
    footer p {
        font-size: 13px;
    }
    footer table td, th {
        font-size: 12px;
    }
    .footer-social-link i {
        font-size: 16px;
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}
