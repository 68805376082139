.service-card {
    background-color: white;
    padding: 20px;
    text-align: center;
    position: relative;
    margin-top: 65px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);

}

.service-cart-top {
    height: 250px;
    overflow: hidden;
}

.service-cart-top img {
    width: 100%;
    transition: all 500ms;
    
}

.service-card:hover .service-cart-top img {
    transform: scale(1.2);
}

.service-card h4{
    margin-top: 15px;
    font-size: 20px
    
}

.service-price {
    font-size: 25px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #a3712a;
    color: white;
    padding: 3px 8px 3px 35px;
    clip-path: polygon(100% 0, 100% 50%, 100% 98%, 0% 100%, 25% 50%, 0% 0%);
}

.btn-service {
    padding: 12px 35px;
    margin-top: 20px;
    font-size: 16px;
    color: #a3712a;
    background-color: #a3712a00;
    border: 1px solid #a3712a;
    border-radius: 25px;
    margin-bottom: 10px
}

