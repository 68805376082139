.ContactImg {
    height: 750px;

}

.TagImg{
    /* align center */
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
}

input, textarea {
    display: block;
    width: 100%;
    padding: 15px;
    margin: 12px 0;
    border: none;
    background-color: #f7f7f7;
    border-radius: 3px;
}

input:focus-visible {
    outline: none
}

.contact-us input[type=submit] {
    background-color: #a3712a;
    color: white;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3px;
}

.contact-us input[type=submit]:hover {
    background-color: #77501a;
}



.contact-us {
   margin-right: 5rem;
   background-color: rgba(255, 255, 255, 1);
   margin-left: -150px;
   padding: 65px;
   box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
}


.contact-us h3 {
    font-size: 35px;
    margin-bottom: 25px;
}

@media (max-width: 576px) {
    .ContactImg {
        display: none;
    
    }
    .contact-us
    {
        margin-left: 0px;
        margin-right: 0px;
        padding: 35px;
    }
}