.side-nav {
    background-color: rgb(27, 26, 26);
    width: 100%;
    height:100vh;
    color: white;
    align-self: center;  
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 35px 15px;
}

.side-nav ul {
       list-style: none;
       padding: 0;
      
}

.side-nav ul li {
   margin: 25px 0;
   text-align: start;
   cursor: pointer;
   font-size: 18px;
   transition: all 500ms;
   padding: 5px 5px 5px 35px;
 
}

.side-nav ul li:hover {

   transform:translateX(25px);
}



.active{
   background-color: #a3712a  ;
   transform:translateX(25px);
   border-radius: 25px;
   color: white;
}





.side-nav ul li i {
    margin-right: 15px;
 }

 .go-home-btn {
    background-color: #a3712a ;
    border: none;
    padding: 8px 45px !important;
    border-radius: 25px;
    color: white;
    margin-top: 25px;
 }

 .go-home-btn:hover {
    background-color: #613e0e ;

 }

 .admin-logo {
    height: 100px;
    margin-bottom: 35px;
 }

 .customer-pic {
    width: 100px;
    border-radius: 500px;
    margin-bottom: 15px;
    border : 2px solid rgba(255, 255, 255, 0.5)
 }