.add-service-container {
    margin: 20px;
    background-color: white;
    padding: 45px;
    border-radius: 10px;
    position: relative;
    width: 600px;
    height: 575px;
    margin: auto;
    
}



.spinner-position {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 3rem;
    width: 3rem;
}


.add-service-container input[type=submit] {
    background-color: #a3712a;
    color: white;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3px;
}

.add-service-container input[type=submit]:hover {
    background-color: #77501a;
}
