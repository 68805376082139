.custon-card {
    padding: 25px 15px;
    background-color: #f7f7f7;
    border-radius: 10px;
    margin: 12px 0;
    
}

.status {
    text-align: end; 
    margin-bottom: 20px;   
}
.status p {
    background-color: white;
    font-weight: 600;
    padding: 5px 15px;
    display: inline;
    border-radius: 25px;
}