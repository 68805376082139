.review-form input[type=submit] {
    background-color: #a3712a;
    color: white;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3px;
}

.review-form input[type=submit]:hover {
    background-color: #77501a;
}
