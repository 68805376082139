.add-appointment-section input[type=submit] {
    background-color: #a3712a;
    color: white;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 3px;
}

.add-appointment-section input[type=submit]:hover {
    background-color: #77501a;
}


.services-section {
    text-align: center;
    border: 1px solid lightgray;
    width: 300px;
    background-color: #f7f7f7;
}

select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}


.add-appointment-section span {
    color: red;
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
}


.payment-section {
    background-color: white;
    height: 200px;
    width: 500px;
    margin: 0 auto;
    text-align: center;
    padding: 25px;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 25px;;
}

/* Paypal */

.payPal {
    width:80%;
    height:42px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color:#ffc439;
    cursor: pointer;
  }
  
  .responsivePayPal {
    height: 36px;
    width: 200px;
    overflow: hidden;
    margin: auto;
    padding-top: 2px;
  }
  
  .responsivePayPal img {
    position: relative;
    top: -4px;
    left: -14px;
  }
  
  .payPalWhite {
    width:50%;
    height:42px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color:#138dd8;
  }
  
  .responsivePayPalWhite {
    height: 36px;
    width: 100px;
    overflow: hidden;
    margin: auto;
    padding-top: 2px;
  }
  
  .responsivePayPalWhite img {
    position: relative;
    top: 5px;
  }